@import '../lib/foundation/scss/motion-ui/motion-ui';

// COLORS
$red: #e9365d; //#e31b23;
$dark-red: #921117;

$blackboard: #1A1E24;
$darker-charcoal: #212227; //#252525;
$dark-charcoal: #282a30; //#363636;
$medium-charcoal: #3c3c3c;
$light-charcoal: #303137; //#484848;

$aqua: #98e5ff;
$dark-blue: #20698c;
$gray-brown: #6c6459;
$darker-gray: #555555;

$demo-textile: #3adb76;
$demo-graphic: #ffae00;
$demo-industrial: #ec5840;

$demo-tags: #f7941d;
$quality-tags: #87a65a;
$spec-tags: #529cb9;

$subheader: #8a8a8a;
// $link: #2199e8;

$topbar-background-trans: rgba(40, 42, 48, 0.85); //rgba(60, 60, 60, 0.85);

h1, h2, h3, h4, h5, h6, p, div, span {
	&.light {
		font-weight: 300;
	}
}

// Custom Transitions

.widget-trigger-out {
	@include mui-hinge(
	  $state: out,
	  $from: top,
	  $turn-origin: from-back,
	  $duration: 0.25s,
	  $timing: easeInOut
	);
}

.widget-trigger-in {
	@include mui-hinge(
	  $state: in,
	  $from: top,
	  $turn-origin: from-back,
	  $duration: 0.25s,
	  $timing: easeInOut
	);
}

.widget-toggle-panel-in {
	@include mui-hinge(
	  $state: in,
	  $from: bottom,
	  $turn-origin: from-back,
	  $duration: 0.25s,
	  $timing: easeInOut
	);
}

.widget-toggle-panel-out {
	@include mui-hinge(
	  $state: out,
	  $from: bottom,
	  $turn-origin: from-back,
	  $duration: 0.25s,
	  $timing: easeInOut
	);
}

.widget-sibling-in {
	@include mui-slide(
	  $state: in,
	  $direction: left,
	  $amount: 100%,
	  $fade: true,
	  $duration: 0.25s,
	  $timing: easeInOut
	);
}

.widget-sibling-out {
	@include mui-slide(
	  $state: out,
	  $direction: right,
	  $amount: 100%,
	  $fade: true,
	  $duration: 0.25s,
	  $timing: easeInOut
	);
}

.user-loc-slide-out {
	@include mui-slide(
	  $state: out,
	  $direction: right,
	  $amount: 100%,
	  $fade: true,
	  $duration: 0.25s,
	  $timing: easeInOut
	);
}

.user-loc-fade-in {
	@include mui-fade(
	  $state: in,
	  $duration: 0.25s,
	  $timing: easeInOut
	);
}

.user-loc-fade-out {
	@include mui-fade(
	  $state: out,
	  $from: 1,
	  $to: 0,
	  $duration: 0.25s,
	  $timing: easeInOut
	);
}

//Other

$section-header-height: 20rem;
// $button-radius: 5px;