@import '../lib/foundation/scss/settings/_settings';
@import '../lib/foundation/scss/foundation';
// @import '../lib/foundation/scss/motion-ui/motion-ui';
@import '_site-vars';

// Import Material Design Icons as well
$mdi-font-path:        "../lib/mdi/fonts";
@import '../lib/mdi/scss/materialdesignicons.scss';

@include foundation-everything;
@include motion-ui-transitions;

/*
//	MIXINS
*/

@mixin input-placeholder-color ($color) {
	&:-moz-placeholder { color: $color; } 
	&::-moz-placeholder { color: $color; }
	&:-ms-input-placeholder { color: $color; }
	&::-webkit-input-placeholder { color: $color; }
}

@mixin disable-href-print ($linkClass: "") {
	@if $linkClass == "" {
		@media print {
			a[href]:after {
				content: none !important;
			}
		}
	} @else {
		@media print {
			a.#{$linkClass}[href]:after {
				content: none !important;
			}
		}
	}
}

// @mixin card-tags {

// }

@mixin tag-filter-button ($color) {
	background-color: $color; 
	border-color: darken($color, 15%);
	&.off:hover { border-color: darken($color, 15%); color: darken($color, 15%); }
	&:not(.off):hover { background-color: darken($color, 10%); }
}

@mixin keywords ($color) {
	.keywords {
		padding-bottom: $global-padding;
		.explanation {
			display: block;
			font-size: rem-calc(12);
			text-transform: uppercase;
		}
		.keyword {
			display: inline-block;
			border: 1px solid;
			color: $white;
			background-color: $color; border-color: darken($color, 15%);
			text-transform: uppercase;					
			padding: 0.2rem 0.25rem;
			font-size: rem-calc(10);
		}
	}
}

@mixin datatable-default {
	.dataTables_wrapper {
		padding-top: $global-padding;
		.display-info {
			font-size: rem-calc(12);
			color: $black;
			line-height: 1.5rem;
			label {
				font-size: inherit;
			}
			select {
				border-radius: 0;
				height: 1.5rem;
				padding: 0 0.5rem;
				option { text-align: center; }
			}
			.dataTables_info {
				padding: 0 0.25rem;
				display: inline;
				line-height: 1.5rem;
			}
		}
		.dataTables_filter {
			input {
				border: none;
				background-color: $light-gray;
				box-shadow: none;
				font-size: rem-calc(12);
				@include input-placeholder-color($black);
			}
		}
		.dataTables_paginate {
			.pagination {
				margin-top: $global-padding;
				text-transform: uppercase;
				width: auto;
				a:hover { color: $primary-color; }
			}
		}
		table.dataTable {
			// Styling for collapsed responsive table popup notice
			&.dtr-inline.collapsed > tbody > tr:not(.child) {
				&:hover {
					td:first-child:after {
						display: block;
						content: "\25bc \2002 CLICK TO VIEW MORE";
						background-color: $primary-color;
						color: $white;
						position: absolute;
					    left: 7px;
					    bottom: 50%;
					    margin-bottom: 10px;
					    padding: 4px 10px 4px 4px;
					    font-size: 10px;
					    opacity: 1;
					    visibility: visible;
					    transition: opacity ease 0.25s;
					    border-radius: 3px;
					    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2);
					    animation: timedfadeout 2s ease-out forwards;
					}
					// td:first-child:focus:after {
					// 	opacity: 0!important;
					// 	animation: none;
					// }
					@keyframes timedfadeout {
						80% { opacity: 1; }
					  	100% { opacity: 0; visibility: hidden; }
					}
				}
			}
			&.dtr-inline.collapsed > tbody > tr.parent:hover > td:first-child:after {
				content: "\25bc \2002 CLICK TO VIEW LESS"!important;
			}
			td, th {
				border-left-style: dotted;
			}
			thead {
				tr {
					// &.smaller { 
					// 	th {font-size: 0.75rem; }
					// }
					// &.smallest { 
					// 	th {font-size: 0.65rem; }
					// }
					th {
						text-align: center;
						font-size: rem-calc(12);
						padding-top: 1rem;
						padding-bottom: 1rem;
						small {
							display: block;
							padding-top: 0.5rem;
							font-size: inherit;
							font-weight: normal;
							// white-space: nowrap;
						}
					}
				}
			}
			tbody {
				font-size: rem-calc(12);
				tr {
					&.even { background-color: smart-scale($table-background, $table-color-scale)}
					&.child {
						border-top: 1px solid $darker-gray;
						border-bottom: 1px solid $darker-gray;
						td.child {
							ul {
								width: 100%;
								text-align: left;
								li {
									.dtr-title {
										display: block;
										div { display: inline; }
										// width: 50%;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@mixin light-headings {
	h1, h2, h3, h4, h5, h6 {
		font-weight: 300;
	}
}

@mixin hollow-button-color ($color) {
	border-color: $color;
	color: $color;
}

@mixin content-defaults ($textWidth) {
	// @include breakpoint (large) {
		// padding-right: 100% - $textWidth;
	// }
	@include disable-href-print("lightbox"); // Disable printed hrefs for lightbox links
	h1, h2, h3, h4, h5, h6 {
		color: $red;
		@include breakpoint (large) {
			width: $textWidth;
		}	
	}
	h5 { font-size: 1rem; text-transform: uppercase; margin-top: 2rem; }
	hr { width: $textWidth; }
	p, .paragraph {
		// color: $dark-gray;
		font-size: rem-calc(14);
		line-height: 1.5rem;
		@include breakpoint (large) {
			width: $textWidth;	
		}
	}
	ul, ol, dl {
		font-size: rem-calc(14);
		@include breakpoint (large) {
			width: $textWidth;	
		}
	}
	dl {
		dt:not(:first-of-type) {
			padding-top: $global-padding;
		}
	}
	table {
		thead {
			tr {
				th { font-size: 0.9rem; }
			}
		}
	}
	.definition {
		display: block;
		font-size: 0.75rem;
		line-height: 1.5;
		margin-bottom: 0.5rem;
		b { color: $primary-color; }
	}
	.keywords {
		@include breakpoint (large) {
			width: $textWidth;
		}
	}
	.contact-form {
		@include breakpoint (large) {
			width: $textWidth;
		}
	}
	form {
		.callout > p { 
			width: 100%;
		}
		.fieldset {
			legend {
				background-color: $white;
				color: $primary-color;
				text-transform: uppercase;
				font-size: rem-calc(12);
			}
			// label.optional {
			// 	&:after {
			// 		content: '(OPTIONAL)';
			// 		font-size: rem-calc(12);
			// 	}
			// }
		}
		textarea {
			height: 6rem;
		}
		.form-dupe-row-remove-button {
			background: none;
			color: $alert-color;
			margin: rem-calc(22) 0 0 0;
			opacity: 0.8;
			&:hover { opacity: 1; }
			i {
				font-size: rem-calc(24);
				line-height: rem-calc(24);
			}
		}
	}
	.default-width {
		h5 { width: 100%; }
		display: block;
		@include breakpoint (large) {
			width: $textWidth;	
		}
	}

	/* CONTENT SPECIFIC STYLING */
	h5#drawbar-options + table, h5#hardware-options + table {
		font-size: rem-calc(14);
		color: inherit;
		thead, tbody, td, th, tr {
			border: 0;
			background-color: transparent;
			color: inherit;
			font-size: inherit;	
		}
	}
	.gallery {
		padding-top: 0.5rem;
		.gallery-image {
			// max-height: rem-calc(80);
			// overflow-y: hidden;
			margin-bottom: $global-padding;
			&:hover {
				transform: scale(1.1, 1.1);
			}
		}
	}
	.painpoints {
		padding: $global-padding 0rem;
		.explanation {
			display: block;
			font-size: rem-calc(12);
			text-transform: uppercase;
			padding-bottom: 0.5rem;			
		}
		.painpoint {
			display: block;
			// border: 1px solid;
			color: $primary-color;
			// background-color: $color; 
			// border-color: darken($color, 15%);
			text-transform: uppercase;
			padding: 0.25rem 1rem;
			font-size: rem-calc(12);
			line-height: rem-calc(18);
			&:before {
				content: mdi('alert-octagon');
				font-family: "Material Design Icons";
				text-align: center;
				display: block;
				float: left;
				// background-color: $primary-color;
				font-size: rem-calc(18);
				// padding: rem-calc(5);
				width: rem-calc(24);
				color: $primary-color;
				margin-right: 0.25rem;
				line-height: rem-calc(18);
			}
		}
	}
	@include breakpoint (large) {
		blockquote {
			position: absolute;
			width: 100% - ($textWidth + 5%)!important;
			right: 0;			
		}
	}
	@include breakpoint (small only) {
		blockquote {
			margin-left: 2rem!important;
			width: auto!important;
		}
	}
	blockquote {
		width: $textWidth;
		margin-left: 4rem;
		border-left: 4px solid $primary-color;
		p {
			font-size: rem-calc(20);
			color: $primary-color;
			width: 100%;
			img { display: block; margin-bottom: 0.5rem; }
			small { font-size: rem-calc(12); line-height: rem-calc(16); }
		}
	}
	// .footnote-ref > a {
		// background-color: $primary-color;
		// color: $white;
		// border-radius: 50%;
	// }
	ol > li[id^="fn:"] {
		font-size: 0.75rem;
		&:first-of-type {
			margin-top: 3rem;
			// border-top: 1px solid $primary-color;
			&:before {
				position: absolute;
				margin-top: -1.5rem;
				content: 'FOOTNOTES';
				color: $dark-gray;
			}
		}
	}
}

@mixin table-stacked-with-labels {
	@include breakpoint(small down) {
		@include table-stack;
		tr { padding: $global-padding;}
		td { padding: 0.5rem; border: none; text-align: left; }
	}
	.stacked-label {
		@include show-for-only(small);
		display: block;
		font-weight: bold;
		margin-right: $global-padding;
		padding-bottom: 0.25rem;
		&:after {
			content: ":";
		}
	}
}

/*
// GLOBAL STYLES
*/

// Based on: http://foundation.zurb.com/sites/docs/responsive-navigation.html#preventing-fouc
.no-js {
  // @include breakpoint(small only) {
  //   .top-bar {
  //     display: none;
  //   }
  // }

  @include breakpoint(medium) {
    .menu-toggle {
      display: none;
    }
  }
}

.show-for-pdf {
	display: none;
}

.overline {
	text-decoration: overline;
}

input[type="search"] { -webkit-appearance: none; }

// Material Design Icons
// ---------------------

i.mdi {
	font-size: 24px;
}

// Font Awesome Overrides
.fa {
	font: normal normal normal rem-calc(24)/1 "Material Design Icons";
	&:after { color: inherit; }
	&.fa-map-marker:after {		
		content: mdi('map-marker');
	}
}

// Text Colors
// -----------

.text-white { color: $white; }
a.text-white:hover { color: darken($white, 15%); }
.text-aqua { color: $aqua; }
.text-dark-blue { color: $dark-blue; }
.text-gray-brown { color: $gray-brown; }
.text-medium-gray { color: $medium-gray; }
.text-dark-gray { color: $dark-gray; }
.text-charcoal { color: $darker-charcoal; }
.text-black { color: $black; } //$secondary-color; }

// Background Colors
// -----------------

.white { background-color: $white; }

// Filetype Icon Prefixes
// ----------------------
.filetype-icons {
	&:before {
		font: normal normal normal rem-calc(24)/1 "Material Design Icons";
		line-height: 1;
		float: left;
		padding-right: 0.5rem;
		color: $primary-color!important;
		content: mdi('file')
	}
}

.filetype-pdf { &:before { content: mdi('file-pdf'); color: #ee3d23; } }
.filetype-doc, .filetype-docx { &:before { content: mdi('file-word'); color: #004a7f; } }
.filetype-xls, .filetype-xlsx { &:before { content: mdi('file-excel'); color: #1d7245; } }
.filetype-ppt, .filetype-pptx { &:before { content: mdi('file-powerpoint'); color: #e24e2d; } }
.filetype-jpg, .filetype-jpeg, .filetype-png, .filetype-gif { &:before { content: mdi('file-image'); color: #0078ff; } }
.filetype-avi, .filetype-mp4 { &:before { content: mdi('file-video'); } }

// Tables
// ------

table {
	font-size: rem-calc(12);
	th, td {
		border-left: 1px solid $darker-gray;
		&.nowrap { white-space: nowrap; }
		&.doublewide { min-width: 15rem; }
	}
	thead {
		tr {
			th {
				text-align: center;
			}
		}
	}
	tbody {
		tr {
			td {
				color: $darker-gray;
				text-align: center;
				&:first-of-type { text-align: left; }
			}
		}
	}
	&.responsive-stacked-labels { 
		@include  table-stacked-with-labels;	
	}
	&.product-items-table {
		.stacked-label { display: none; }			
		// td:first-child {
		// 	white-space: nowrap;
		// }
	}
}

// Misc
// ----

.jstemplate { display: none; }

// a .material-icons { float:left; margin-right: 0.25rem; }

.callout {
	&.alert p {
		color: white!important;
	}
}

.warning-message {
	&:before {
	    font: normal normal normal rem-calc(14)/1 "Material Design Icons";
	    content: mdi('alert');
	    color: $warning-color;
	    padding-right: 0.25em;
	    font-size: 1.5rem;
	    float: left;
	}
}

.circle-avatar {
	border-radius: 50%;
	img { border-radius: 50%; }
}

.img-icon-circle-link {
	display: inline-block;
	height: 32px;
	width: 32px;
	text-align: center;
	padding: 8px;
	background-color: $darker-gray;
	border-radius: 50%;
	// opacity: 0.8;
	transition: opacity ease 0.25s;
	overflow: hidden;
	img { height: 14px; width: 14px; }
	i { font-size: 20px; line-height: 15px; margin-left: -2px; }
	&:hover {
		// opacity: 1;
		background-color: $darker-charcoal;
	}
}

.underline {
	text-decoration: underline;
}

button, img {
	&:focus {
		outline: none;
	}
}

.button {
	// &.rounded {
	// 	border-radius: $button-radius;
	// }
	.mdi { font-size: 1rem; }
	&.tight {
		padding: 0.25rem 0.4rem;
	}
	&.hollow {
		&.white { @include hollow-button-color($white); }
		&.charcoal { @include hollow-button-color($darker-charcoal); }
		&.black { @include hollow-button-color($black); }
		&.dark-blue { @include hollow-button-color($dark-blue); }
		&.aqua { @include hollow-button-color($aqua); }
	}
}

// body {
// 	background-color: darken($dark-charcoal, 10%);
// }

p.ignore { // Workaround for extraneous <p> tags inserted by BlackFriday
	padding: 0!important;
	margin: 0!important;
	width: 100%!important;
}

.content {
	padding: $global-padding;
	padding-top: 2rem;
	// h1, h2, h3, h4, h5, h6, p {
	// 	&:first-child {
	// 		padding-top: $global-padding;
	// 	}
	// }
	@include content-defaults(60%);
	@include datatable-default;
}

.tabs {
	li {
		&.is-active {
			a {
				color: $primary-color;
			}
		}
		a {
			color: $black;
			text-transform: uppercase;
			&:hover {
				color: $primary-color;
			}
		}
	}
}

.tabs-content {
	nav { display: none; } // Temp fix for BlackFriday TOC being arbitrarily output in some content when h6 used first before h5
	.tabs-panel {
		position: relative;
		.contact-notice {
			font-size: rem-calc(14);
			// line-height: rem-calc(22);
			// text-align: center;
			// background-color: whitesmoke;
			// background: transparent;
			// border: none;
			padding: $global-padding 0;
			// margin-top: 1rem;
			// margin-bottom: 0!important;
			.mdi {
				display: block;
				color: $primary-color;
				font-size: rem-calc(48);
				height: rem-calc(48);
				line-height: rem-calc(48);
				margin-right: 0.75rem;
			}
		}
	}
}

// Spinner: https://boguz.github.io/Spinners/
.single10 {
  height: 50px;
  width: 50px;
  border: 8px solid lighten($primary-color, 25%);
  border-radius: 50%;
  position: relative;
  -webkit-animation: single10anim 2s infinite linear;
  animation: single10anim 2s infinite linear;
}

.single10:before {
  content: '';
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: $primary-color;
  position: absolute;
  top: -7px;
  left: 14px;
}
  
@-webkit-keyframes single10anim {
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}   
@keyframes single10anim {
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

// From: https://www.ostraining.com/blog/coding/responsive-videos/
.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    iframe{
	    left: 0;
	    top: 0;
	    height: 100%;
	    width: 100%;
	    position: absolute;
	}
}

.scrollable-image-frame {
		// background-color: purple;
		position: relative;
	&::-webkit-scrollbar {
  	width: 5px;
		}
	&::-webkit-scrollbar-thumb {
  	background: $primary-color;
 		// border-radius: 5px;
	}
  &::-webkit-scrollbar-track {
    background-color: #9b2740;
  }
	&.horizontal {
		overflow-x: auto;

	}
	// img {	}
}

@include breakpoint (medium down) {
	.scrollable-image-frame {
		&::before {
			display: block;
			position: absolute;
			top: 50%;
			width: 6rem;
			text-align: center;
			font: normal normal normal rem-calc(14)/1 "Material Design Icons";
		  content: mdi('gesture-swipe-left');
			left: 50%;
			margin-left: -3rem;
			color: $white;
			font-size: rem-calc(48);
			line-height: 0;
			// opacity: 0.5;
			animation: Pulse 3s infinite ease-in-out;
		}
	}
}

@keyframes Pulse {
    0% { transform: scale(0.95); opacity: 0.5; }
    50% { transform: scale(1); opacity: 1; }
    100% { transform: scale(0.95); opacity: 0.5; }
}

// .js-shave-char {
// 	cursor: help;
// 	&:hover + .cut-text {
// 		display: block!important;
// 	}
// }

/*
// MULTIPLE PAGE MODULES
*/

// Top Bar
// -------

.top-bar, .footer {
	.logo {
		img { 
			max-width: 240px;
		}
		.sublogo {
			padding-top: 4px;
			width: 240px;
			text-align: right;
		}
	}
}

.top-bar-wrapper {
	z-index: 1001;
	@include breakpoint (small only) {
		height: 60px;
	}
}

.top-bar {
	@media screen and (max-width: 25em) {
		padding-left: $global-padding;
		padding-right: $global-padding;
	}
	@include breakpoint (small only) {
		position: fixed;
		padding-top: 0;
		padding-bottom: 0;
	}
	&.top-bar-floating {
		background-color: $topbar-background-trans;
		position: absolute;
		top: 0;
		width: 100%;
		// z-index: 15;
	}
	width: 100%;
	// z-index: 15;
	background-color: $topbar-background;
	padding: 2.5rem 2rem $global-padding 2rem;
	.menu a { color: $white; }
	#menu-main { padding-right: 2rem; }
	.logo { 
		height: 60px;
		text-align: center;
		@include breakpoint (medium up) {
			text-align: left;
			// .logo-img { padding-left: 1.5rem; }
		}
		@include breakpoint (small only) {
			.logo-img {
				padding-left: 2rem;
				padding-right: 2rem;
				img {
					margin-top: 10px;
					margin-bottom: 2rem;
				}
			}
		}
		.menu-toggle {
			position: absolute;
			// padding-bottom: $global-padding;			
			// display: inline-block;
			// float: left;
			// margin-top: 10px;
			// text-align: left;		
			// line-height: 1.5rem;
			a { 
				font-size: 1.5rem;
				// color: $white;
				display: inline-block;
				width: 100%;
				// text-transform: uppercase;
				i { 
					font-size: 40px;
					color: $primary-color;
					// margin-right: 0.5rem;
				}
			}
		}
	}
	.site-search-input {
		border: 1px solid $darker-gray;
		border-top-left-radius: 4px;
    	border-top-right-radius: 0;
    	border-bottom-right-radius: 0;
    	border-bottom-left-radius: 4px;
		max-width: 100%;
		-webkit-appearance:none;
		&:focus {
			box-shadow: none;
		}
	}
	ul {
		background: none;
		// li:not(.is-dropdown-submenu-parent):not(.is-accordion-submenu-parent) { // Style for active menu items w/ no children
		.no-children {
			&:last-of-type { 
				@include breakpoint (medium up) { width: 7.6rem; }
			}
			&.active {				
				a {
					background: none;
					opacity: 0.5;			
				}
			}
		}
		.featured-items {
			list-style: none;
			margin-left: 0;
			li {
				// padding-left: 0.25rem;
				.mdi {
					font-size: 1rem;
					line-height: 1;
				}
				a {
					display: block;
					padding: $menu-item-padding;
					line-height: 1.5;
				}
			}
		}
		&.menu {
			font-size: 0.75rem;
			text-transform: uppercase;
			white-space: nowrap;
		}
		&.dropdown > li { 
			i.mdi {
				margin-bottom: 3px;
			}
			.mdi-chevron-down {
				transition: transform ease 0.15s;
			}
			&.is-active {
				background-color: $light-charcoal;
				.mdi-chevron-down {
					transform: rotateZ(-180deg);
				}
			}
			&.is-dropdown-submenu-parent {
				&.active {
					&:not(.is-active) { opacity: 0.5; }
					a {
						background-color: inherit;
					}
				}
			}			
		}
		&.is-dropdown-submenu {
			background: $light-charcoal;
			border: none;
			padding-bottom: $global-padding;
			a { white-space: normal; line-height: 1.5; }
			&.nested { 
				margin-left: 0;
			}
			li { 
				text-transform: none;
				&:hover {
					background-color: $darker-charcoal;
				}
				&.active {
					background-color: $darker-charcoal;									
				}
			}
		}
		&.accordion-menu {
			padding-bottom: $global-padding;
			li {
				&.is-accordion-submenu-parent {
					.mdi-chevron-down { display: none; }
				}			
			}
			ul.is-accordion-submenu {
				&.is-active {
					// background: $topbar-background;
				}
			}
		}
	}
}

// Footer
// ------

.footer {
	background-color: $topbar-background;//$medium-charcoal;
	padding: 2em;
	color: $white;
	font-size: 0.8rem;
	a {
		color: $white;
		&:hover {
			color: $primary-color;
			text-decoration: underline;
		}
	}
	h5 {
		font-size: rem-calc(20);
	}
	.logo {
		margin-bottom: 2rem;
	}
	#footer-phone { margin-bottom: 2rem; }
	// &> .row { padding: 0 1em }
	.site-toc {
		h6 { 
			font-size: rem-calc(12);
			font-weight: 500;
			text-transform: uppercase;
			margin-bottom: $global-padding;
		}
		.menu {
			li {
				a { 
					font-size: rem-calc(12);
					font-weight: 300;
					padding: 0.125rem 0;
					// color: $white;
				}
			}
			padding-bottom: 3rem;
		}
	}
	.footer-hr {
		border-top: 2px solid lighten($medium-charcoal, 25%);
		height: 0.5rem;
		// margin-top: 2rem;
	}
	.footer-note {
		h6 {
			font-size: rem-calc(12);
			font-weight: 500;
			text-transform: uppercase;
		}
		p {
			font-size: rem-calc(12);
			font-weight: 300;
			a { text-decoration: underline; }
		}
	}
	.footer-lower {
		padding-top: 0.25rem;
		small {
			margin-right: $global-padding;
			font-weight: 300;
		}
		@include breakpoint(small only) {
			text-align: center;
		}
	}
	.social-links {
		text-align: right;
		h7 {
			// float: left;
			display: inline-block;
			line-height: 32px;
			margin-right: $global-padding;
		}
		a {
			margin-left: 0.25rem;
			&:hover {
				color: inherit;
			}
		}
		@include breakpoint (medium down) {
			padding-top: 2rem;
		}
	}
}

// Share Widget
// ------------

.widgets-wrapper .widget {
	height: 3rem;
	.widget-trigger {
		padding: 0.75rem 2rem;
		.trigger-button {
			display: inline-block;
			text-transform: uppercase;
			font-size: 0.75rem;
			line-height: 1rem;
			&:hover {
				text-decoration: underline;
			}
			i { 
				padding-right: 0.5rem;
				font-size: 1rem;
			}
		}
	}
	.widget-toggle-panel {
		padding: 0.5rem 2rem;
		.cancel-button { margin-right: 0.5rem; }
		span.tool-tip {
			display: inline-block;
		}
		a {
			background: none;
			color: inherit;
			i.mdi {
				font-size: 20px;
				line-height: 1;
			}
			&:not(.cancel-button){
				opacity: 0.8;
				&:hover { opacity: 1; }
			}
		}
	}
}

// Search Widget
// -------------

.site-search {
	transition: opacity ease 0.25s;
	margin-top: 4px;
	margin-bottom: 0;
	// margin-right: $global-padding;
	width: auto;
	// &.always-active { opacity: 1; }
	&.inactive:not(.always-active) {
		opacity: 0.5;
		&:hover { opacity: 1; }
	}
	&.mobile {
		padding-bottom: 0.5rem;
		margin-right: 0;
	}
	.site-search-btn {
		height: 36px;
		i { font-size: 20px; }
	}
}
.site-search-result-dropdown-wrapper {
	position: absolute;
    width: 98%;
    left: 1%;
    @include breakpoint(large) {
    	top: rem-calc(120);
    }
    @include breakpoint(small) {
    	top: 15vh;
    }
	#site-search-result-dropdown {
		opacity: 0;
		border-radius: 0;
		padding: 0;
		right: 0;
		top: 24px;
		width: 100%;
		max-height: 75vh;	
		background-color: $white; // $topbar-background;
		border: 1px solid $black; // none;
		box-shadow: 0 8px 17px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
		transition: opacity 0.5s, visibility 0.5s;
		&.is-open { opacity: 1; }
		.header {
			// padding: rem-calc(8);
			min-height: 53px;
			position: relative;
			#site-search-close-wrapper {
				position: absolute;
				right: rem-calc(8);
				top: 50%;
				z-index: 1000;
				height: rem-calc(40);
				width: rem-calc(40);
				line-height: rem-calc(40);
				margin-top: rem-calc(-20);
				text-align: center;
				@include breakpoint(small only) {
					top: rem-calc(8);
					margin-top: 0;
				}
			}			
			#site-search-results-tabs-wrapper {
				@include breakpoint(small only) {
					.content-tabs { padding: 0 1rem; }
				}
			}
		}
		.footer {
			background-color: $topbar-background;
			height: rem-calc(48);		
			padding: rem-calc(8);
			line-height: rem-calc(32);
		}
		.site-search-results-wrapper {
			max-height: calc(75vh - 103px);
			@include breakpoint(small only) {
				max-height: calc(75vh - 206px);
			}
			overflow-y: auto;
			padding: $global-padding;
			padding-top: 0;
			width: 100%;
			#site-search-spinner {
				margin: 2rem auto;
			}
			h5 { margin-top: $global-padding; margin-bottom: $global-padding; }
			.products-card-wrapper {
				.products-card {
					// &.product-highlight {
					// 	border: none;
					// 	.image {
					// 		background-size: contain;
					// 		background-repeat: no-repeat;
					// 	}
					// 	.name {
					// 		text-align: center;
					// 		font-size: rem-calc(14);
					// 		line-height: 1.25;
					// 		color: $secondary-color;
					// 	}
					// }
				}
			}
		}
	}
}

dl.search-results {
	width: 100%;
	dt {
		small {
			color: lighten($subheader, 10%);
			font-weight: normal;
			a { 
				color: inherit;
				&:hover {
					text-decoration: underline;
				}
			}
			// text-decoration: underline;
		}
	}
	dd {
		font-size: 0.75rem;
		color: $subheader;
		padding-bottom: $global-padding;
	}
}

// Orbit Slider
// ------------

.orbit {
	.orbit-controls-wrapper {
		text-align: center;
		position: absolute;
		bottom: 0;
		padding-bottom: 1.5rem;
		width: 100%;
		.orbit-controls {
			display: inline-block;
			.orbit-bullets {
				display: inline-block;
				button {
					background: none;
					border: 2px solid $white;
					opacity: 0.8;
					box-shadow: 0 0 7px rgba(54, 54, 54, 0.8);
					&:hover { opacity: 1; }
					&.is-active {
						background-color: $white;
					}
				}
			}
			.orbit-previous, .orbit-next {
				text-shadow: 0 0 7px rgba(54, 54, 54, 0.8);
				transform: none;
				position: relative;
				display: inline-block;
				height: 36px;
				opacity: 0.8;
				&:hover {
					opacity: 1;
				}
			}
			.orbit-previous { float: left; }
			.orbit-next { float: right; }
		}
	}
	.orbit-slide {
		background-color: $white;
		.hero-collage {
				.feature {
					background-size: cover;
					&.full { width: 100%; border-right: none; }
				}
				.side {
					.top, .bottom {
						background-size: cover;
					}
				}
			}
		@include breakpoint (medium up) {
			.hero-collage {
				height: 520px;
				@include grid-row;
				.feature {
					height: 100%;
					border-right: 5px solid $white;
					@include grid-column(8, 0);
				}
				.side {
					@include grid-column(4, 0);
					height: 100%;
					.top, .bottom, .full {						
						@include grid-column;
						@include grid-column-end;
					}
					.top {
						height: 265px;
					}
					.bottom {
						height: 255px;
						border-top: 5px solid $white;
					}
					.full {
						height: 520px;
					}
				}
			}
		}
		@include breakpoint (small only) {
			.hero-collage {
				// height: 540px;
				@include grid-row;
				.feature {
					height: 300px;
					border-bottom: 4px solid $white;
					@include grid-column;
					@include grid-row;
					&.full { height: 500px; }
				}
				.side {
					@include grid-column;
					@include grid-column-collapse;
					height: 200px;
					.top, .bottom {
						height: 100%;
						@include grid-column(6, 0);
						@include grid-column-end;
					}
					.top {
						@include grid-column-position(6);
						border-left: 4px solid #fefefe;
					}
					.bottom {
						@include grid-column-position(-6);
						border-left: none!important;						
					}
					.full {
						height: 100%;
						background-size: cover;
						@include grid-column(12, 0);
					}
					.bottom {
						border-left: 4px solid $white;
					}
				}
			}
		}
	}
	.orbit-caption-hero-label {
		font-size: 0.75rem;
		line-height: 1rem;
		position: absolute;
		// font-weight: 300;
		@include breakpoint (medium down) {
			left: 10!important;
			right: 10!important;
			bottom: 10!important;
			max-width: 100%!important;
		}
	}
	.orbit-caption-hero {
		// border: 1px solid red;
		position: absolute;
		top: 50%;
		padding-left: 4rem;
		// .product-logo { 
		// 	height: 40px;
		// 	margin-bottom: 0.5rem;
		// }
		color: $white;
		h5 {
			// color: $white;
			// font-weight: 300;
			font-size: 1rem;
			text-transform: uppercase;
			width: 60%;
			white-space: normal;
			line-height: 125%;
			font-weight: bold;
		}
		h1 {
			font-weight: 500;
			// color: $white;
			width: 60%;
			white-space: normal;
			line-height: 100%;
			margin-bottom: $global-padding;
		}
		.button.call-to-action {
			font-weight: bold;
			text-transform: uppercase;
			border-radius: 0;
			border-width: 2px;
			margin-top: 0.75rem;
			padding: 0.6rem 1.2rem;
			&:hover {
				border-color: $black;
				background-color: $black;
			}
			&.charcoal:hover, &.black:hover {
				border-color: $white;
				background-color: $white;
			}
		}
		@include breakpoint (medium down) {
			top: 30%;
			text-shadow: 0 0 7px rgba(54,54,54,0.8);
			h1, h5 {
				&.text-charcoal {
					text-shadow: 0 0 7px rgba(255,255,255,0.8);
				}
			}
			.call-to-action {
				text-shadow: 0 0 7px rgba(54, 54, 54, 0.8);
				&.charcoal { text-shadow: 0 0 7px rgba(255, 255, 255, 0.8); }
			}
		}
		@include breakpoint (small only) {
			// text-shadow: 0 0 7px rgba(54,54,54,0.8);
			top: 10%;
			padding: 0 2rem;
			h1, h5 {
				width: 100%;
				// &.text-charcoal {
				// 	text-shadow: 0 0 7px rgba(255,255,255,0.8);
				// }
			}
			h1 {
				font-size: 2rem;
			}
		}
	}
	.orbit-image {
		width: auto;
		max-width: initial;
	}
	@include breakpoint (small only) {
		.orbit-previous, .orbit-next {
			top: 90%;
		}
	}
}


// Landing Pages
// -------------

.landing-page {
	.landing-page-tile {
	 	&.hero {
			position: relative;
			display: flex;
			width: 100%;
			min-height: 600px;
			background-position-x: center;
			background-position-y: center;
			background-size: cover;
			// border-bottom: 8px solid $primary-color;
			.overlay {
				&:before {
					content: '';
					width: 100%;
					height: 100%;
					position: absolute;
					left: 0;
					top: 0;
					z-index: 1;
					background-color: rgba($black, 0.82);
					mix-blend-mode: hard-light;
				}
				&.right { margin-left: auto; }
				display: flex;
				position: relative;
				width: 50%;
				min-height: inherit;
				@include breakpoint (medium down) {
					width: 100%!important;
				}
			}
		}
		&.interstitial {
			display: flex;
			flex-direction: row;
			background-color: $blackboard;
			&:after {
				display: block;
				content: '';
				clear: both;
			}
			.interstitial-image, {
				margin-left: 2rem;
				// margin-right: 3rem;
			}
			.interstitial-image-frame {
				margin-left: 2rem;
				background-repeat: no-repeat;
				background-size: cover;
			}
			.interstitial-video {
				flex: 2 1 185rem;
				.video-responsive { height: 100%; }
			}
			.interstitial-panels {
				display: flex;
				flex-direction: row;
				.panel {
					flex: 1 1 auto;
					padding: 3rem;
					&.img-bg {
						background-size: cover;
						// background-color: rgba($black, 0.82);
 						// background-blend-mode: multiply;
					}
					&.img-blend {
						position: relative;
						// border-right: 4px solid #1A1E24;
						&::before {
							content: '';
					    width: 100%;
					    height: 100%;
					    position: absolute;
					    left: 0;
					    top: 0;
					    z-index: 1;
							background: linear-gradient(to bottom, rgba($blackboard, 1.0) 0%, rgba(0, 0, 0, 0) 15%);
						}
					}
					&.img-darken {
						background-color: rgba(0, 5, 25, 0.7);
						background-blend-mode: multiply;
						background-repeat: no-repeat;
					}
					&.start-at-bottom {
						display: flex;
						flex-direction: column;
						place-content: flex-end;
					}
					.panel-content {
						position: relative;
						z-index: 10;
						padding: 0!important;
						&.half-width {
							width: 50%;
						}
					}
				}
			}
			.landing-page-tile-content { padding-left: 4rem; padding-right: 4rem; }
		}
		.landing-page-tile-content {
			z-index: 2;			
			margin-top: auto;
			margin-bottom: auto;
			padding: 3rem;
			&.section-header {
				width: 100%;
				background-size: cover;
				text-align: center;
				padding-top: 4rem;
				padding-bottom: 4rem;
				h2 {
					display: inline;
					background-color: $blackboard;
					padding: 0 1.5rem;
				}
			}
			&.section-content {
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				gap: 4rem;
				padding-bottom: 4rem;
				.sidebar {
					position: relative;
					flex-basis: 16rem;
					flex-grow: 1;
					.anchor {
						position: absolute;
						bottom: 0%;
						.toc-return {
							display: inline-block;
							line-height: rem-calc(12);
							font-size: rem-calc(12);
							width: 10rem;
							color: $white;
							i.mdi {
								float: left;
								margin-right: 0.5rem;
								height: rem-calc(24);
								&:before {
									line-height: rem-calc(24);
								}
							}
						}
					}
				}
				.main {
					flex-basis: 0;
					flex-grow: 999;
				}
			}
			h1, h2, h3, h4, h5, h6 { 
				color: $white;				
				font-weight: 300;
				&.expanded { line-height: 1.5; }
				&.subsection { margin-top: 1rem; margin-bottom: 1rem; }
				.emph { color: $primary-color; }
				& + p { margin-top: 1rem; }
			}
			h1 { font-size: rem-calc(42); }
			h2 { 
				font-size: rem-calc(36);
				.mdi { 
					font-size: inherit;
					&.primary { color: $primary-color; }
				}
			}
			p {
				color: $white;
				line-height: 2;
			}
			ul {
				color: $white;
				padding-left: 2rem;
				&.no-bullets { list-style: none; }
			}
			img.inset-left { float: left; padding-right: 2rem; padding-bottom: 2rem; }
			.callout.outline {
				border-color: $primary-color;
				background: none;
				clear: both;
			}
			.button.hollow.white:hover {
				border-color: $primary-color;
				color: $primary-color;
			}
			.form-embed { 
				padding-left: 14rem;
				padding-right: 14rem;				
				.form-wrapper {
					background-color: $white;
					padding: 2rem 2rem 4rem 2rem;
					p { color: $body-font-color; }
				}
			}
			.toc {
				padding-left: 4rem;
				padding-right: 4rem;
				padding-bottom: 4rem;
				.toc-intro {
					padding-left: 8rem;
					padding-right: 8rem;
					padding-bottom: 4rem;
				}
				ul.toc-items {
					list-style: none;
					margin-left: 0;
					padding-right: 2rem;
					li {
						height: 5rem;
						// background-size: 50%;
						background-size: contain;
						background-repeat: no-repeat;
						margin-bottom: 1.5rem;
						position: relative;
						a {
							display: inline-block;
							position: absolute;
							bottom: 0%;
							border-bottom: 2px solid $primary-color;
							color: $white;							
							h4 {
								margin-left: 10rem;
								margin-bottom: 0;
								background-color: $blackboard;
								padding: 0 1rem;
								.emph { color: $primary-color; }
							}
						}
					}
				}
			}
		}
	}
	hr {
		margin: 0;
		border: 4px solid $primary-color;
	}
	@include breakpoint (medium down) {
		.landing-page-tile {
			&.interstitial {
				flex-wrap: wrap;
				.interstitial-image { height: 300px; margin-left: auto; margin-right: auto; margin-top: 3rem; width: auto!important; }
				.interstitial-image-frame { height: 300px; margin-top: 3rem; margin-left: 0; width: 100%; background-position: center; background-size: contain; }
				.landing-page-tile-content { 
					padding-left: 2rem; 
					padding-right: 2rem;
					.form-embed { 
						padding-left: 4rem;
						padding-right: 4rem;
						.title { padding-left: 4rem; padding-right: 4rem; }
					}
					.toc {
						padding-left: 0;
						padding-right: 0;
						.toc-intro { padding-left: 4rem; padding-right: 4rem; }
						ul.toc-items li { height: 6rem; }
					}
					&.section-content {
						gap: 2rem;
						.sidebar {
							flex-basis: auto;
							padding-right: 8rem;
							padding-bottom: 3rem;
							h4 { 
								border-left: 2px solid $primary-color;
								padding-left: 2rem;
							}
							.anchor { right: 0%; }
						}
						.main {
							flex-basis: auto;
						}
					}
				}
			}
		}
	}
	@include breakpoint (small only) {
		.landing-page-tile {
			&.interstitial {
				.interstitial-panels { 
					flex-direction: column;
					.panel {
						&.img-blend.no-blend-on-mobile {
							border-top: 4px solid #1A1E24;
							&::before { display: none; }
						}
						.panel-content.half-width { width: 100%; }
					}
				}
			}
			.landing-page-tile-content {
				padding: 2rem;
				h1 { font-size: rem-calc(36); }
				h2 { font-size: rem-calc(28); }
				p { font-size: rem-calc(14); }
				img.inset-left { float: none; display: block; margin: 0 auto; padding: 2rem 0; }
				.form-embed { 
					padding-left: 0rem!important;
					padding-right: 0rem!important;
					.title { padding-left: 4rem; padding-right: 4rem; }
				}
				.toc {
					.toc-intro { padding-left: 0!important; padding-right: 0!important; }
					ul.toc-items { 
						padding-left: 0;
						padding-right: 0;
						margin-left: 0;
						li {
							background-size: cover;
							a h4 { margin-left: 2rem; }
						}
					}
				}
			}
		}
	}
}



// Breadcrumbs
// -----------

.breadcrumbs-top {
	background: $white;	
	.breadcrumbs {
		padding: 0.125rem;
		margin-bottom: 0;
		@include breakpoint (medium down) {
			padding-left: 2rem;
		}
		li:not(:last-child) {
			&::after {
			    color: $black;
			    // font-weight: 300;
			    content: "\00bb";
			    margin: 0 0 0 0.25rem;
			    position: relative;
			    font-size: 0.8rem;
			    line-height: 0.6875rem;
			    opacity: 1;			    
			}
			&.extra {
		    	// color: green!important;
		    	margin-right: 0.5rem;
		    	&::after {
		    		content: '/';
		    		font-size: inherit;
		    	}
		    }
		}
	}

}

// Section Headers
// ---------------

.section-header {
	// height: $section-header-height;
	background-color: $primary-color;
	.section-header-title {
		position: relative;
		height: $section-header-height;		
		padding: 2.5rem;
		padding-bottom: 3rem;
		color: $white;
		background-color: $primary-color;
		z-index: 2;
		overflow: hidden;
		.subtitle {
			margin-bottom: 0;
			font-weight: 300;
			text-transform: lowercase;
		}
		.title {
			font-weight: 300;
			font-size: 3.75rem;
			line-height: 4rem;
			word-wrap: break-word;
			hyphens: auto;
			position: relative;
			&.shrink-text {
				font-size: 3rem;
				line-height: 3rem;
			}
			&.mega-shrink-text {
				font-size: 2.25rem;
				line-height: 1.25
			}
		}
		.widgets-wrapper {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			background: desaturate(darken($primary-color, 8%), 15%);
			.widget  {
				float: left;
				.widget-trigger {
					.trigger-button {
						color: $white;
					}
				}
				.widget-toggle-panel {
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					height: 3rem;
					// background: darken($primary-color, 15%);
				}
			}
		}
	}
	.section-header-hero {
		height: $section-header-height;
		background-size: cover;
		//background-image: url('https://source.unsplash.com/xJ2tjuUHD9M/1010x320');
		position: relative;
		color: $white;
		.explanation-widget {
			transition: 0.25s ease-in-out;
			position: absolute;			
			left: 0;
			top: 0;
			width: 49%;			
			font-size: rem-calc(12);
			background-color: rgba(0, 0, 0, 0.75);			
			height: 20rem;
			z-index: 1;
			&.hidden {
				left: -50%;
				.info-widget-btn {
					transform: rotateZ(180);
					cursor: help;
					.mdi.close { display: none; }
					.mdi.open { display: block;  }
				}
			}
			.info-widget-btn {
				.mdi.open { display: none; }
				color: $white;
				position: absolute;
				right: -55px;
				top: 10px;
				// background-color: rgba(54, 54, 54, 0.5);
				border: 2px solid $white;
				border-radius: 50%;
				// padding: rem-calc(3);
				height: rem-calc(40);
				width: rem-calc(40);
				line-height: 36px;
				text-align: center;
				&:hover {
					background-color: $black;
					border-color: $black;
				}
			}
			.explanation {
				display: block;
				position: relative;
				height: 100%;
				overflow-y: auto;
				padding: $global-padding;			
			}
		}
		.caption {			
			font-size: rem-calc(9);
			position: absolute;
			right: 0;
			bottom: 0;
			width: 50%;
			text-align: right;
			padding-right: 2rem;
			padding-bottom: 1rem;
			text-transform: uppercase;
			a { 
				color: $white;
				&:hover {
					text-decoration: underline;
				}
			}
			@include breakpoint (medium down) {
				width: 100%;
			}
		}
	}
	&.video-header { // Scripting for headers w/ embedded video
		@include breakpoint(large up) {
			display: flex;
			flex-direction: row;
		}
		.section-header-title {
			flex: 1;
			height: auto;
		}
		.section-header-hero {
			flex: 1;
			height: auto;
			padding: 0;
		}
	}
	@include breakpoint(medium down) {
		.section-header-title {
			padding-left: 2rem;
			padding-right: 2rem;
			height: auto;
			.title {
				font-size: 3rem;
			}
		}
		.section-header-hero {
			height: $section-header-height / 2;
			background-position: center;
			.explanation-widget {
				width: 100%;
				height: ($section-header-height / 2) - rem-calc(60);
				&.hidden {
					left: 0;
					top: (($section-header-height / 2) - rem-calc(60)) * -1;
				}
				.info-widget-btn {
					left: 24px;
					right: auto;
					top: auto;
					bottom: -45px;
				}
				.explanation {
					height: ($section-header-height / 2) - rem-calc(60);
				}
			}
		}
	}
}

// Filter Components
// -----------------

.filter-container {
	padding-top: 2rem;	
	text-transform: uppercase;
	font-size: rem-calc(11);
	font-weight: bold;
	color: $darker-charcoal;
	.title {
		font-size: rem-calc(10);
		text-align: right;
		padding-bottom: 0.5rem;
	}
	.filter-status {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		border-bottom: 2px solid $secondary-color;
		margin-bottom: $global-padding;
	}
	.filter-label {
		display: inline-block;
		// float: left;
		padding: 0;
		line-height: 2rem;
		// margin-right: $global-padding;
	}
	.filter-reset {
		display: inline-block;
		color: $secondary-color;
		opacity: 0.5;
		padding-left: $global-padding;
		i.mdi { 
			float: left;
			font-size: $global-font-size; 
			// margin-top: 3px;
			margin-right: 2px;
		}
	}
	.tag-filter {
		margin-bottom: 0.5rem;
		&.radio.active {
			.button.off {
				// opacity: 0.5;
				@include tag-filter-button($light-gray);
				color: darken($light-gray, 25%);
			}
		}
		.button {
			border-radius: 0;
			border-width: 1px;
			text-transform: uppercase;
			margin-right: 0.5rem;
			margin-bottom: 0.5rem;
			font-size: rem-calc(10);
			&:last-child { margin-right: 0; }
			&.quality_standard { 
				@include tag-filter-button($quality-tags);
			}
			&.cost_standard { 
				@include tag-filter-button($quality-tags);
				padding-left: 1rem;
				padding-right: 1rem;				
			}
			&.spec_ink-type, &.spec_adhesive-type, &.spec_emulsion-type { 
				@include tag-filter-button($spec-tags);
			}
			&.demographic { 
				@include tag-filter-button($demo-tags);
			}
			// &.demographic-graphic { background-color: $demo-graphic; }
			// &.demographic-industrial { background-color: $demo-industrial; }
			&.off {
				color: $secondary-color;
				background-color: transparent;
				border-color: $secondary-color;
				&:hover {
				// 	background-color: $black;
					// color: $white;
				}
			}
		}
	}
}

.product-card.filter-hidden {
	display: none;
	// opacity: 0.5;
	// .expanded-content {
	// 	display: none;
	// }
	// .hidden-icon { display: block!important; }
}

.products-card-wrapper.filter-hidden {
	opacity: 0.5;
}

// Sales Rep Finder Widget
// -----------------------

.sales-rep-finder {
	&.default {
		display: flex;
		width: 100%;
		height: 100%;
		padding: 0;
		margin-top: 1rem;
		margin-bottom: 1rem;
		background-color: $primary-color;
		color: $white;
		a { 
			color: $white;
			&:hover {
				color: $white;
				text-decoration: underline;
			}
		}
		p { margin-bottom: 0!important; /* Work around for BlackFriday <p> bug */ }
		.rep-pic {
			// display: table-cell;
			// width: 34%;
			height: 200px;
			// height: 100%;
			// .framed-avatar {
			// 	display: inline-block;
			// 	width: 100%;
			// 	height: 100%;
			// 	background-size: cover;
			// 	background-position: center;
			// }
		}
		.rep-desc, .rep-info {
			@include disable-href-print;
			margin-top: auto;
			margin-bottom: auto;
			font-size: rem-calc(12);
			line-height: rem-calc(18);
			word-break: break-all;
			// vertical-align: top;
			.name {			
				font-weight: 500;
			}
			padding: 10px;
			// padding-top: 10px;
			// font-size: .8em;
		}
		.rep-desc {
			padding: 1rem 1rem;
			display: table-cell;
			width: 66%;
		}
	}
	&.landing {
		.select-location-wrapper {
			color: $white;
			font-size: rem-calc(12);
			.select-loc-btn {
				color: $white;
				text-decoration: underline;
				margin-top: 0.5rem;
			}
		}
	}
	.select-loc-btn {
		display: inline-block;
		margin-top: 1rem;
		i {
			float: right;
			font-size: 1rem;
			line-height: 1.25rem;
		}
	}
	.select-location-select {
		min-width: 14rem;
		position: relative;
		.location-list {
			position: absolute;
			height: 50vh;
			margin-top: -40vh;
			overflow-y: scroll;
			padding: 0!important;
			background-color: $light-charcoal;
			box-shadow: 0 8px 17px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
			list-style-type: none;
			li {
				padding: 0;
				width: 100%;
				// position: relative;
				font-size: rem-calc(12);
				&.active {
					background-color: $darker-charcoal;
				}
				a { 
					color: $white;
					display: inline-block;
					width: 100%;
					padding: 0.5rem 1rem;
					&:hover {
						text-decoration: none;
						background-color: $darker-charcoal;
						color: $white;
					}
				}
				// cursor: pointer;
				// &:hover {
				// 	background-color: $darker-charcoal;
				// }
				ul { 
					// position: relative;
					list-style-type: none;
					margin-left: 0;
					padding-left: 0;
					width: 100%;
					li {
						width: 100%;
						a { padding-left: 2rem;}
						&.group-label {
							background-color: $topbar-background;
							color: $white;
							font-weight: bold;
							padding: 0.5rem 1rem 0.5rem 1rem;
							cursor: default;
						}
					}
				}
			}
		}
	}
}

// Tab Types
// ---------

.content-tabs {
	border-bottom: 1px solid $black;
	font-weight: 500;
	font-size: 0.75rem;
	position: relative;
	z-index: 100;
	li {
		position: relative;
	}
	@include breakpoint (medium up) {
		li {
			&.is-active {
				&:after {
					content: '';
				    display: block;
				    width: 0;
				    height: 0;
				    border: inset 6px;
				    border-color: $secondary-color transparent transparent;
				    border-top-style: solid;
				    border-bottom-width: 0;
				    position: absolute;
					top: 100%;
					left: 50%;
					margin-left: -6px;
				}
			}
		}
	}
	@mixin tab-arrow-left {
		content: '';
	    display: block;
	    width: 0;
	    height: 0;
	    border: inset 6px;
	    border-color: transparent $secondary-color;
	    border-left-style: solid;
	    border-right-width: 0;
	    position: absolute;
		top: 50%;
		margin-top: -6px;
		left: 0;
	}
	@include breakpoint (small only) {
		li {
			width: 100%;
			&.is-active {
				&:after {
					@include tab-arrow-left;
				}
			}
		}
	}
	&.break-on-medium {
		@include breakpoint (medium down) {
			li {
				width: 100%;
				&.is-active {
					&:after {
						@include tab-arrow-left;
					}
				}
			}
		}
	}
}


/*
// HOME PAGE MODULES
*/

// Section Links
// -------------

.section-links-wrapper {
	font-weight: 300;
	// @include light-headings;
	display: table;
	min-height: 6rem;
	width: 100%;
	color: $white;
	background: $red;
	border-top: 4px solid $white;
	&:last-child { border-bottom: 4px solid $white; }
	.section-links {
		// display: table-row;
		// min-height: 8rem;
		background-color: $dark-red;
		.section-link-intro {
			// display: table-cell;
			// width: 20%;
			// height: 8rem;
			padding: 1.5rem 3rem;
			text-align: left;
			vertical-align: middle;
			background-color: $red;
		}
		.section-link {
			// display: table-cell;
			// width: 20%;
			// box-sizing: content-box;
			// padding: 2rem;
			text-align: center;
			vertical-align: middle;
			// border-right: 1px solid $red;
			// background-color: darken($red, 15%);
			// &:last-child { border-right: none; }
			a {
				// border: 1px solid white;
				display: block;
				box-sizing: border-box;
				width: 100%;
				height: 6rem;
				padding: 0rem $global-padding;
				&:hover {
					background: url('../img/design/net-pattern.png');
					background-size: 10px 10px;
				}
				span {
					display: inline-block;
					font-size: 1.25rem;
					line-height: 1.5rem;
					// border: 1px solid blue;
					padding: 0.5rem $global-padding;
					// margin: 1rem;
					background: $dark-red;
					color: $white;
					margin-top: 3rem;
					transform: translateY(-50%);
					-ms-transform: translateY(-50%);
					-webkit-transform: translateY(-50%);
					text-align: center;
					// vertical-align: middle;
					// margin: 2rem;
				}
			}
		}
	}
	@include breakpoint(medium) {
		.section-links {
			display: table-row;
			.section-link-intro {
				display: table-cell;
				width: 20%;
			}
			.section-link {
				display: table-cell;
				width: 20%;				
				// box-sizing: content-box;
				border-right: 1px solid $red;
				&:last-child { border-right: none; }
			}
		}
	}
	@include breakpoint(medium only) {
		.section-links {
			.section-link {
				a {
					padding: 0;
					height: 8rem;
					span {
						padding: 0;
						margin-top: 4rem;
						// text-align: center;
					}
				}
			}
		}
	}
	@include breakpoint(small only) {
		width: 50%;
		float: left;
		&:last-child { 
			border-bottom: none;
			border-left: 4px solid $white;
		}
		.section-links {
			.section-link {
				border-bottom: 1px solid $red;
				&:last-child { 
					border-bottom: 4px solid $white; 
				}
				// a span { text-align: center; }
			}
		}
	}
	@media screen and (max-width: 25em) { // Extra small (400px)
		.section-links {
			.section-link-intro {
				padding: 1.5rem;
			}
			.section-link {
				a {
					span {
						font-size: 1rem;
					}
				}
			}
		}
	}
}


// Section Menu
// ------------

.menu.section-menu {
	padding: 2rem 1rem 2rem 0;
	text-align: right;
	font-size: rem-calc(12);
	font-weight: 600;
	text-transform: uppercase;
	li {
		a {
			color: $secondary-color;
		}
		&.active {
			a { 
				background-color: transparent;
				color: $primary-color;
			}			
		}
	}
	@include breakpoint (medium down) {
		text-align: left;
	}
}


// Standard Content Pages
// ----------------------

.content-container {
	position: relative;
	padding-top: 2rem;
	padding-bottom: 4rem;
	@include content-defaults(66.66667%);
	table { width: 66.66667%; }
	@include breakpoint (medium down) {		
		padding: 2rem;
	}
	// @include breakpoint (large) {
	// 	blockquote {
	// 		position: absolute;
	// 		width: 30%!important;
	// 		right: 0;			
	// 	}
	// }
	// @include breakpoint (small only) {
	// 	blockquote {
	// 		margin-left: 2rem;
	// 		width: auto!important;
	// 	}
	// }
	// blockquote {
	// 	width: 80%;
	// 	margin-left: 4rem;
	// 	border-left: 4px solid $primary-color;
	// 	p {
	// 		font-size: rem-calc(20);
	// 		color: $primary-color;
	// 		width: 100%;
	// 		img { display: block; margin-bottom: 0.5rem; }
	// 		small { font-size: rem-calc(12); line-height: rem-calc(16); }
	// 	}
	// }
}

.content-container.full-width {
	@include content-defaults(100%);
	table { width: 100%; }
}


// Content Specific Styling
// ------------------------

.content-container.terms {
	p {
		font-size: rem-calc(12);
		line-height: 1.6;
		&:first-of-type {
			line-height: 2;
		}
	}
	ul { font-size: rem-calc(12); }
	ol {
		font-size: rem-calc(12);
		li { padding-bottom: $global-padding; }
		p { font-size: inherit; margin-bottom: 0; }
		ol { font-size: inherit; width: 100%; }
	}
}

.content-container.team {
	.batavia {
		margin-left: 2rem;
		width: 60%;
		@media screen and (max-width: 30em) {
			display: block;
			float: none!important;
			margin-left: auto;
			margin-right: auto;
			width: 100%;
			padding-bottom: 2rem;
		}
	}
}

.content-container.timeline {
	dl {
		padding-top: 2rem;
		dt {
			display: block;
			position: absolute;
			margin-top: -16px;
			padding: 0;
			background-color: $primary-color;
			border: 2px solid darken($primary-color, 10%);
			border-radius: 50%;
			color: $white;
			text-align: center;
			height: rem-calc(55);
			width: rem-calc(55);
			line-height: rem-calc(55);
			font-size: rem-calc(14);
			font-weight: normal;
			&:after {
				content: " ";
				display: block;
				position: absolute;
				top: 50%;
				left: 100%;
				width: rem-calc(20);
				border-top: 1px solid darken($primary-color, 10%);
			}
		}
		dd {
			display: block;
			padding-bottom: 4rem;
			border-left: 1px solid darken($primary-color, 10%);
			margin-left: rem-calc(27.5);
			padding-left: rem-calc(52);
			&:last-of-type {
				border-left: 0;
			}
		}
	}
}

.content-container.whitepaper {
	table {
		margin-top: $global-padding;
		margin-bottom: 0;
	}
	a.figure {
		display: inline-block;
		margin-top: $global-padding;
		margin-right: $global-padding;
		&:last-of-type {
			margin-right: 0;
		}
	}
	.caption {
		display: block;
    	color: $primary-color;
    	font-size: rem-calc(12);
    	padding-top: 0.5rem;
    	em {
    		font-style: normal;
    		font-weight: bold;
    	}
	}
	.callout {
		@include breakpoint (large) {
			width: 66.66667%;
		}
		p { width: 100%; }
	}
	@include keywords($primary-color);
}

// /product/ page list of products
// .product-list {
// 	list-style-type: none;
// 	li {
// 		display: block;
// 		padding: 0.5rem;
// 		border: 1px solid $black;
// 		margin-bottom: 0.5rem;
// 	}
// }

#salesmap {
	height: 240px;
	border: 1px solid $secondary-color;
	margin-top: 1rem;
	.leaflet-popup-content {
		.marker-description { display: none; }
	}
	.leaflet-bar-part {
		.fa {
			position: absolute;
			top: 0px;
			left: 3px;
		}
	}
}

#business-cards {
	padding-top: 1rem;
	h6 {
		font-size: rem-calc(12);
		width: 15rem;
		margin: 0.25rem auto 1rem auto;
		padding-left: 1rem;
		color: $medium-gray;
	}
	.business-card-orbit {
		margin: 0rem auto;
		width: 15rem;
		.orbit-container { width: 100%; height: 365px!important; }
		.orbit-slide {
			border: 1px solid $medium-gray;
			margin-top: 3px;
			margin-left: 3px;
			box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
			padding: $global-padding;
			width: 14.5rem;
			font-size: rem-calc(12);
			div { line-height: rem-calc(16); }
			.name-block {
				height: 8rem;
				.name { color: $primary-color; font-weight: bold; }
			}
			.logo { margin-bottom: 2rem;}
			.phone {
				b {	display: inline-block; width: 3rem; }
			}
			.address { margin-top: 1rem;}
			.url { margin-top: 1rem; }
		}
		.orbit-previous, .orbit-next {
			top: 12rem;
			color: $medium-gray;
			opacity: 0.4;
			transition: opacity ease 0.25s;
			&:hover { opacity: 1; }
			i { font-size: rem-calc(36); }
		}
		// .orbit-controls {
		// 	text-align: center;
		// 	.orbit-bullets {
		// 		display: inline-block;
		// 		button {
		// 			background: none;
		// 			border: 2px solid $medium-gray;
		// 			opacity: 0.8;
		// 			&:hover { opacity: 1; }
		// 			&.is-active {
		// 				background-color: $medium-gray;
		// 			}
		// 		}
		// 	}
		// 	.orbit-previous, .orbit-next {
		// 		transform: initial;
		// 		position: relative;
		// 		display: inline-block;
		// 		height: 36px;
		// 		opacity: 0.8;
		// 		color: $medium-gray;
		// 		&:hover {
		// 			opacity: 1;
		// 		}
		// 	}
		// 	.orbit-previous { float: left; }
		// 	.orbit-next { float: right; }
		// }
	}
}


// Product/Products Cards
// ----------------------

.card-direct-view {
	.hide-on-direct-view { display: none; }
}

.products-card-wrapper {
	// height: 16rem;
}
.products-card {
	padding-top: 0;
	padding-right: 0;
	padding-bottom: 0;
	padding-left: 0;
	margin-top: 1rem;
	border-radius: 0;
	border: 1px solid $secondary-color;
	height: rem-calc(200);
	// @include card-tags;
	overflow-y: hidden;
	// transition: scale 0.25s ease-in-out;
	transition: ease 0.25s;
	.title {
		// margin-top: $global-padding;
		padding: 0.5rem 1rem 0.5rem 0.5rem;
		// padding: 0.5rem;
		font-size: rem-calc(14);
		line-height: 1.25;
		// font-weight: 300;
		text-align: left;
		color: $darker-gray;
		background-color: $light-gray;
		min-height: rem-calc(67);
		margin-bottom: 0;
		&:after {
		    font: normal normal normal rem-calc(14)/1 "Material Design Icons";
		    content: mdi('play');
		}
	}
	.image {
		height: rem-calc(133);
		background-position-x: center;
    	background-position-y: center;
    	background-size: cover;
    	-webkit-filter: grayscale(100%);    	
    	filter: grayscale(100%);  
	}
	&:hover {
		transform: scale(1.1, 1.1);
		.image { -webkit-filter: grayscale(0%); filter: grayscale(0%); }
	}
	&.product-highlight {
		border: none;
		.image {
			background-size: contain;
			background-repeat: no-repeat;
		}
		.name {
			text-align: center;
			font-size: rem-calc(14);
			line-height: 1.25;
			color: $secondary-color;
		}
	}
}

.product-card-container {
	padding-left: 2rem;
	padding-right: 2rem;
	padding-bottom: 4rem;
	.product-card-anchor {
		position: absolute;
		margin-top: -3rem;
	}
}

@include breakpoint (large up) {
	.product-card-container {
		padding-left: 0;
		padding-right: 5rem;
	}
}

.product-card {
	margin-top: 2rem;
	border-radius: 0;
	border: 1px solid $secondary-color;
	// @include card-tags;
	&.standalone {
		border: 0;
		margin-top: 0;
		.card-header { display: none; }
		.content-tabs {
			padding-left: 8.33333%; // Matches large-offset-1 used on Product Solution page
		}
		.tabs-content {
			padding: 3rem 8.33333%;
			.tabs-panel {
				padding: 1rem;
					// Free Shipping banner standalone card position hacks
					.freeshipping {
						left: calc(-8.33333% - 1rem);
						@include breakpoint(medium down) {
							left: -8.33333vw;
						}
						@media screen and (max-width: 34em) {
							top: -3rem;
						}
					}		
			}
		}
	}
	.card-header {
		padding: $global-padding;
		padding-left: 2rem;
		padding-bottom: 0.25rem;
		border-right: 20px solid $primary-color;
		background-color: $light-gray;
		.product-title {
			color: black;
			font-size: rem-calc(24);
			font-weight: bold; 
			padding-bottom: $global-padding;
		}
	}
	.tags {
		min-height: 2rem;
		font-size: rem-calc(11);
		.tag-group {
			display: inline-block;
			padding-right: 1.5rem;
			padding-bottom: 1rem;
			// &:last-of-type {
			// 	padding-bottom: none;
			// }
			.title {
				display: block;
				text-transform: uppercase;
				font-size: rem-calc(10);
				color: $darker-gray;
				// font-size: 0.75em;
			}
			&[class*=' quality'] { .tag { background-color: $quality-tags; border-color: darken($quality-tags, 15%); } }
			&[class*=' cost'] { .tag { background-color: $quality-tags; border-color: darken($quality-tags, 15%); padding-left: 1rem; padding-right: 1rem; } }
			&[class*=' spec'] { .tag { background-color: $spec-tags; border-color: darken($spec-tags, 15%); } }
			&.demographic { .tag { background-color: $demo-tags; border-color: darken($demo-tags, 15%); } }
		}
		.tag {
			text-transform: uppercase;
			border-radius: 0;
			border: 1px solid;
			padding: 0.2rem 0.25rem;
			font-size: rem-calc(10);
			// height: 1.5rem;
			&.initial { width: 1.5rem; }
			// &.demographic { background-color: $demo-tags; }
			// &.textile { background-color: $demo-textile; }
			// &.graphic { background-color: $demo-graphic; }
			// &.industrial { background-color: $demo-industrial; }
		}
	}
	.header-image {
		margin: 0;
		margin-bottom: 1em;
	}
	.card-content {		
		// padding: $global-padding;
		.tabs-content {
			position: relative;
			&:after { /// Compensate for float:right product images that extend beyond div
				content: "\00a0";
				display: block;
				width: 100%;
				height: 1px;
				clear: both;
			}
			.pricecomp, .freeshipping {
				position: absolute;
				background-color: $light-gray;
				border-radius: 0;
				top: 0;
				padding: 0 0.5rem 0 0;
				line-height: rem-calc(24);
				color: $dark-gray;
				b {
					color: $primary-color;
					// font-weight: normal;
				}
				&:before {
					// content: "\F4F9";
					font-family: "Material Design Icons";
					text-align: center;
					display: block;
					float: left;
					background-color: $primary-color;
					font-size: rem-calc(16);
					// padding: rem-calc(5);
					width: rem-calc(24);
					color: $white;
					margin-right: 0.5rem;
				}			
			}
			.pricecomp {
				right: 0;
				&:before { content: "\F4F9"; }
			}
			// Free Shipping banner card position hacks
			.freeshipping {
				top: -3rem;
				left: -3rem;
				@include breakpoint(medium down) {
					left: -2rem;
				}
				// @include breakpoint(small only) {
				@media screen and (max-width: 34em) {
					top: -2rem;
				}
				&:before { content: "\F76B"; }
				.learn-more {
					font-weight: bold;
					margin-left: 0.5rem;
					a:after {
						content: "\F53D";
						font-family: "Material Design Icons";
						text-align: center;
						display: block;
						float: right;
						// background-color: $primary-color;
						font-size: rem-calc(16);
						// padding: rem-calc(5);
						width: rem-calc(24);
						color: $primary-color;
						// margin-left: 0.5rem;
					}
				}
			}
			ul.small-print {
				position: relative;
				list-style: none;				
				margin-left: 2rem;
				li {
					font-size: rem-calc(12);
					font-style: italic;
					&:before {
						content: "\F6C3";
						font-family: "Material Design Icons";
						font-style: normal;
						text-align: center;
						display: block;
						position: absolute;
						left: -2.1rem;
						// background-color: $primary-color;
						font-size: rem-calc(16);
						// padding: rem-calc(5);
						width: rem-calc(24);
						color: $primary-color;
					}
				}
			}
		}
	}
	&:not(.standalone) {
		.tabs-title {
			a {
				padding: $global-padding;
			}
		}
		@include breakpoint(small only) {
			.content-tabs {
				padding: 0 1rem;
				li {
					width: 100%;
					text-align: left;
				}
				border-top: 1px solid $medium-charcoal;
			}
		}
		@include breakpoint(medium) {
			.content-tabs {
				padding: 0 4rem;
				li {
					width: 33.33%;
					text-align: center;
				}		
			}
		}
	}
	.tabs-content {
		padding: 3rem;
		padding-top: 2rem;
		.tabs-panel {
			// overflow: auto;
			&.panel-1, &.panel-3 {
				@include content-defaults(80%);
				.product-image {
					float: right;
					padding-left: $global-padding;
					padding-bottom: $global-padding;		
				}
				@media screen and (max-width: 34em) {
					.product-image {
						display: block;
						float: none;
						margin-left: auto;
						margin-right: auto;
					}
				}
				@include breakpoint(large) {
					.product-image {
						margin-right: -20%;
					}
				}
			}			
			ul.download-list {
				list-style-type: none;
				font-size: rem-calc(14);
				li {
					clear: both;
					padding: 0.25rem 0;
					a { 
						// color: $black;
						&:hover { text-decoration: underline; }
					}
					.file-explanation {
						color: $dark-gray;
						display: block;
						font-size: rem-calc(12);
						padding-left: 2rem;
    					white-space: pre-wrap;
					}
				}
			}
			.dataTables_wrapper + .related-items-wrapper {
				margin-top: $global-padding;
				// border-top: 1px solid $medium-gray;
			}
			.related-items-wrapper {
				h5 { clear:both; }
			}
			.product-items-date {
				display: block;
				text-align: right;
				font-size: 75%;
				color: #cacaca;
			}	
		}
		// Hacky way of displaying footnotes only when panel 1 is active
		// .panel-1.is-active + p + .panel-2 + .panel-3 + p + .footnotes { display: block }
		.panel-2.is-active + .panel-3 + p + .footnotes, .panel-2.is-active + .panel-3 + .footnotes { display: none; }
		.panel-3.is-active + p + .footnotes, .panel-3.is-active + .footnotes { display: none; }
		.footnotes {
			margin-top: 3em;
			font-size: rem-calc(12);
		}
	}
	@include breakpoint(medium down) {
		.tabs-content {
			padding: 2rem;
		}
	}
	// .hidden-icon {
	// 	display: none;
	// 	position: absolute;
	// 	left: 50%;
	// 	margin-left: - 12px;
	// 	font-size: 32px;
	// }
	.panel-1, .panel-2, .panel-3 { // Downloads Tab, Product Details/Quote Request Tab
		@include datatable-default;
		.contact-form { width: 100%!important; }
	}
	.card-footer {
		background-color: $medium-gray;
		height: 3rem;
		clear: both; // Used to compensate for cards with little text and large floated imgs
    	// padding: 0.25rem;
    	.top-of-card-btn {
    		@include hide-for(large);
    		display: block;
    		float: left;
    		color: $dark-gray;
    		padding-left: 0.5rem;
    		i {
    			height: 3rem;
    			line-height: 3rem;
    		}
    	}
    	.widgets-wrapper {
    		.widget {
	    		position: relative;
	    		text-align: right;
	    		float: right;	
				.widget-trigger {
					.trigger-button {	color: $secondary-color; }
				}
				.widget-toggle-panel {
					a {	color: $secondary-color; }
				}
			}
			.download-pdf-widget {
				float: right;
				a { color: $secondary-color; }
			}
		}
		@media screen and (max-width: 33em) {
			.widget-trigger {
				padding: 0.75rem 1rem;
				text-align: left;
			}
			.share-widget {
				// width: 220px;				
				.widget-toggle-panel {
					.cancel-button {
						float: left;
					}
					padding: 0.5rem;
					position: absolute;
					left: 0;
					bottom: 100%;
					width: 100%;
					background-color: $light-gray;
				}
			}
		}
	}
}

.content-list {
	list-style-type: none;
	margin-top: 2rem;
	p { width: 100%; }
	&.cards, &.resources-whitepapers, &.resources-guides, &.company-news, &.showcase-showcase {
		margin-left: 0;
		li {
			&.section-divider {
				font-size: 2rem;
				background-color: $primary-color;
				color: $white;
				border: 0;
				text-align: center;
				height: 3rem;
				width: 3rem;
				line-height: 3rem;
			}
			position: relative;
			border: 1px solid $secondary-color;
			margin-bottom: $global-padding;
			// padding: 1rem;
			.details {
				padding: $global-padding;			
				.title {
					color: $black;
					font-size: rem-calc(18);
				}
				.summary {
					font-size: rem-calc(12);
					line-height: 1.5;
				}
				@include keywords($primary-color);
				.call-to-action {
					text-align: right;
					.button {
						color: $secondary-color;
						background-color: transparent;
						border-color: $secondary-color;
						margin-bottom: 0;
						font-weight: bold;
						&:hover {
							@include tag-filter-button($secondary-color);
							color: $white;
						}
					}
				}
			}
		}
	}
}

.prop65warning {
	display: block;
	margin-top: 2rem;
	// border-top: 1px solid #8a8a8a;
	// padding-top: 0.5rem;
	font-size: 0.75rem;
	line-height: 1rem;
	i.mdi { color: #ffc84c; }	
}

// Used for anchors in product list or whitepaper list
.card-anchor { 
	position: absolute;
	top: -9rem;
}

@include breakpoint(medium) {
	.sticky-container {
		// padding-top: 2rem;
	}
	.card-anchor { 
		position: absolute;
		top: -3rem;
	}
}

.alpha-grid {
	// margin-top: 2em;
	padding: 2rem 1rem 3rem 1rem!important;
	background: $white;
	.column {
		text-align: center;
		.letter {
			font-size: 1.5rem;			
			&.disabled { color: $medium-gray; }
		}
		a.letter {
			&:hover { text-decoration: underline; }
		}
	}
	@include breakpoint (small only) {
		padding: 3rem $global-padding 0.5rem $global-padding!important;
		left: 0!important;
		max-width: 100%!important;
		border-bottom: 1px solid $dark-gray;
		&:not(.is-stuck) { padding-top: 0.5rem!important; }
		.column {
			width: 7.6%;
			padding: 2px;
			.letter { font-size: 1rem; }
			&:nth-of-type(8n+1) {
				clear: none;
			}
			&:nth-of-type(13n+1) {
				clear: both;
			}
		}
	}
}

@media print {
	.print-header {
		padding: 2rem;
		img.logo { max-width: 240px!important; }
	}
	.section-header {
		.section-header-title {
			height: auto;
			padding-top: 1rem;
			padding-left: 2rem;
			padding-right: 2rem;
			padding-bottom: 2rem;
			h1 { 
				font-size: 2rem!important; 
				span { font-size: 100%!important; }
			}
			.widgets-wrapper { display: none; }
		}
		.section-header-hero {
			display: none;
		}
	}
	.filter-container {
		padding-top: 0;
		text-align: left!important;
		float: left!important;
		.title { 
			text-align: left!important;
			font-size: 11px!important;
			padding-bottom: 0!important;
		}
		.filter-reset { display: none!important; }
		.filter-status { border-bottom: none!important; margin-bottom: 0!important; }
		.filter-wrapper {
			padding-left: 2rem;
			.tag-filter {
				text-align: left!important;
				.button {
					background-color: black!important;
					color: white!important;
					border-color: black!important;
					text-decoration: none!important;
					-webkit-print-color-adjust: exact;
					&.off {
						background-color: transparent!important;
						color: black!important;
					}
				}
			}
		}
	}
	.section-menu { display: none!important; }
	.content-tabs { display: none!important; }
	.tabs-panel {
		display: block;
	}
	.product-card-container {
		padding-left: 2rem;
		padding-right: 2rem;
	}
	.product-card {
		.tags {
			.tag { border-color: black!important; }
		}
	}
	.card-content {		
		.product-image {
			// width: 200px;
		}
		.pricecomp {
			&:before {
				background-color: transparent!important;
				color: black!important;
			}
		}
		.panel-2 {
			display: none; // Hide download links
		}
		.panel-3 {
			.dataTables_wrapper { // Item tables in 3rd panel
				padding-top: 2rem;
				.product-items-table {
					&:before { // Add header when printing
						display: block;
						position: absolute;
						content: 'Item Details';
						font-size: 1rem;
					    text-transform: uppercase;
					    top: 0;
					}
				}
				// .product-items-date {
				// 	display: block;
				// 	text-align: right;
				// 	font-size: 80%;
				// }
			}
			.contact-notice { display: none; } // Hide "Questions? Comments?"" link in 3rd panel
			.related-items-wrapper {
				.products-card-wrapper {
					width: 100%;
					.products-card {
						.name { display: inline-block; margin-right: 1rem; }
					}
				}
			}
			.contact-form {
				p {
					page-break-after: avoid!important;
					break-after: avoid!important;
				}
				form {
					page-break-before: avoid!important;
					break-before: avoid!important;
					fieldset {
						page-break-inside: avoid !important;
				        break-inside: avoid !important;
					}
					input[type='text'], input[type='number'], input[type='email'] {
						border: none!important;
						box-shadow: none!important;
						border-bottom: 1px solid $medium-gray!important;
						&:disabled {
							background-color: transparent!important;
						}
					}
					textarea {
						box-shadow: none!important;			
					}
					// ul.select-list {
					// 	list-style-type: none!important;
					// 	li {
					// 		font-size: rem-calc(12)!important;
					// 		&:before {
					// 			display: inline-block;
					// 			padding-right: 0.25rem;
					// 			font: normal normal normal rem-calc(14)/1 "Material Design Icons";
					//     		content: mdi('checkbox-blank-circle-outline');
					// 		}
					// 	}
					// }
					// input[type='radio'] {
					// 	visibility: hidden;
					// 	&:before {
					// 		display: block;
					// 		visibility: visible;
					// 		font: normal normal normal rem-calc(14)/1 "Material Design Icons";
				 //    		content: mdi('checkbox-blank-circle-outline');
					// 	}
					// }
					input[type='submit'] {
						display: none!important;
					}
					.form-dupe-row-button {
						display: none!important;
					}
				}	
			}


		}
		.card-footer { display: none; }
	}
	.dataTables_wrapper {
		.display-info {
			.dataTables_length select { 
				border: none!important; 
				width: auto;
				padding: 0!important;
			}
		}
		.dataTables_filter { display: none!important; }
		.dataTables_paginate { display: none!important; }
		table.dataTable.responsive {
			th, td { display: table-cell!important; }
			thead tr th { padding: 5px!important; }
			tbody tr td:first-child:before { display: none!important; }
		}
	}
	h5 a:after { font-size: 80%!important; }
	.products-card-wrapper {
		height: auto!important;
		.products-card {
			height: auto!important;
			padding: 1rem;
			a {
				.title {
					height: auto!important;
					min-height: auto!important;
					padding: 0;
				}
				.image {
					display: none!important;				
				}
				&:after { font-size: 80%!important;	}
			}
		}
	}

	.content-container.timeline dl dt { background-color: white!important; -webkit-print-color-adjust: exact; } // Ensure opaque background for timeline nodes

	#footer-phone { width: 50%; float: left; }
	#sales-rep-finder {
		width: 50%;
		padding-top: 0;
		.rep-pic { width: rem-calc(110); }
		.rep-desc { float: left; }
	}
	.footer-lower {
		text-align: center;
	}
}
